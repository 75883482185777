<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="合同">
        <el-input v-model="filter.contract" placeholder="名称、编号、拼音" clearable></el-input>
      </el-form-item>
      <el-form-item label="合同日期">
        <el-date-picker
          v-model="filter.billDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="所属项目">
        <project-select v-model="filter.projectId"></project-select>
      </el-form-item>
      <el-form-item label="客户">
        <base-select info="Client" v-model="filter.clientId"></base-select>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-plus" @click="addContract" type="primary">添加</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="data" stripe border @row-dblclick="dblclickTable">
      <el-table-column label="合同编号" show-overflow-tooltip prop="billCode"></el-table-column>
      <el-table-column label="合同日期" prop="billDate" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.billDate|datetime}}</template>
      </el-table-column>
      <el-table-column label="合同名称" prop="contractName" show-overflow-tooltip></el-table-column>
      <el-table-column label="所属项目" prop="projectName" show-overflow-tooltip></el-table-column>
      <el-table-column label="客户" prop="clientName" show-overflow-tooltip></el-table-column>
      <el-table-column label="合同金额" prop="contractAmount" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.contractAmount|thousands}}</template>
      </el-table-column>
      <el-table-column label="结算金额" prop="balanceAmount" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.balanceAmount|thousands}}</template>
      </el-table-column>
      <el-table-column label="剩余金额" prop="surplus" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.surplus|thousands}}</template>
      </el-table-column>
      <el-table-column label="操作"  width="65">
        <template slot-scope="scope">
          <el-tag type="warning" effect="dark" @click="editContract(scope.row)">变更</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 50, 100, 500]"
      @size-change="getData(1)"
      @current-change="getData()"
      :current-page.sync="pageIndex"
      :page-size.sync="pageSize"
      :total="pageTotal"
    ></el-pagination>
    <div>
      <add-edit :item="item" @success="getData()"></add-edit>
    </div>
  </div>
</template>

<script>
import ProjectSelect from "@/components/ProjectSelect.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import AddEdit from "@/views/contract/Item.vue";
import setName from "@/common/setName";
export default {
  components: {
    AddEdit,
    ProjectSelect,
    BaseSelect
  },
  data() {
    return {
      filter: {},
      loading: false,
      data: [],
      pageIndex: 1,
      pageSize: 10,
      pageTotal: 0,
      item: {}
    };
  },
  watch: {
    filter: {
      handler: function(v) {
        this.getData(1);
      },
      deep: true
    }
  },
  activated() {
    this.getData();
  },
  methods: {
    getData(pageIndex) {
      if (pageIndex) {
        this.pageIndex = pageIndex;
      }
      this.loading = true;
      let params = {
        ...this.filter,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      };
      this.$get("Contract/GetAllContract", params)
        .then(r => {
          r.list.forEach(item => {
            setName("Project", item, "projectId", "projectName");
            setName("Client", item, "clientId", "clientName");
          });
          this.data = r.list;
          this.pageTotal = r.pageTotal;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addContract() {
      this.item = {};
    },
    editContract(row) {
      this.item = {
        id: row.id
      };
    },
    dblclickTable(row) {
      this.$router.push({
        name: "ContractDetail",
        params: { id: row.id }
      });
    }
  }
};
</script>

<style>
</style>