<template>
  <div>
    <el-dialog
      :title="item.id?'编辑类别':'新增类别'"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form :model="form" status-icon :rules="rules" ref="form" label-width="60px">
        <el-form-item label="排序">
          <el-input v-model="form.typeCode" placeholder="排序" clearable></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="typeName">
          <el-input v-model="form.typeName" placeholder="名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="工时费" v-if="typeKind=='EmployeeType'">
          <el-input
            type="number"
            v-model="form.price"
            placeholder="工时费"
            clearable
            @mousewheel.native.prevent
          ></el-input>
        </el-form-item>
        <el-form-item label="上级">
          <type-cascader v-model="form.parentId" :info="typeKind"></type-cascader>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button type="info" @click="save(false)" :loading="loading">保存关闭</el-button>
            <el-button type="success" @click="save(true)" :loading="loading" v-if="!item.id">保存新增</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import TypeCascader from "@/components/TypeCascader";
import tree from "@/common/tree.js";
export default {
  components: {
    TypeCascader
  },
  //GoodsType,EmployeeType,ExpenseType,ClientType,SupplierType
  props: ["item", "typeKind"],
  data() {
    return {
      visible: false,
      loading: false,
      form: {},
      rules: {
        typeName: [{ required: true, message: "名称不能为空" }]
      }
    };
  },

  watch: {
    item: {
      handler: function(value) {
        if (value.id) {
          this.initForm(value.id);
        } else {
          this.reset(value.parentId);
        }
        this.visible = true;
      },
      deep: true
    }
  },
  methods: {
    initForm(id) {
      this.loading = true;
      this.$get("Sys/TypeDetail", {
        typeKind: this.typeKind,
        id
      })
        .then(r => {
          this.form = r;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset(parentId) {
      this.form = {
        typeCode: "",
        typeName: "",
        price: null,
        parentId: parentId || null
      };
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
    },
    save(isContinue) {
      this.$refs["form"].validate(valid => {
        if (!valid) {
          return;
        }
        let values = {
          typeKind: this.typeKind,
          ...this.form
        };
        if (values.id && values.parentId) {
          let error = false;
          let array = this.$store.state.baseinfo[this.typeKind];
          let selectedArray = tree.getParentIdArray(array, values.parentId);
          selectedArray.forEach(id => {
            if (id == values.id) {
              error = true;
            }
          });
          if (error) {
            this.$message.error("本身不能包含到上级中");
            return;
          }
        }
        this.loading = true;

        if (values.id) {
          this.$post("Sys/EditType", values)
            .then(r => {
              this.$message.success("操作成功！");
              this.visible = false;
              this.$store.commit("updateBaseInfo", {
                info: this.typeKind,
                item: r
              });
              this.$emit("success", r.id);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$post("Sys/AddType", values)
            .then(r => {
              this.$message.success("操作成功！");
              this.$store.commit("addBaseInfo", {
                info: this.typeKind,
                item: r
              });
              this.$emit("success", r.id);
              if (isContinue) {
                this.reset();
              } else {
                this.visible = false;
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style>
</style>