import store from '@/store/baseinfo'
import http from '@/ajax.js'
const setName = function (type, item, id, name) {
    item[name] = '';
    if (!id) {
        return;
    }
    store.state[type].forEach(d => {
        if (d.id == item[id]) {
            item[name] = d.name
        }
    });
    //停用赋值

    let canStopBaseInfo = ['Supplier', 'Client', 'Goods', 'Store', 'Employee'].indexOf(type) >= 0;

    if (!item[name]) {
        if (canStopBaseInfo) {
            http.get("Sys/GetStopName", {
                type,
                id: item[id]
            }).then(r => {
                item[name] = r;
            })
        } else {
            item[name] = '-';
        }

    }
}

export default setName;