<template>
  <div>
    <el-dialog
      :title="item.id?'编辑客户':'新增客户'"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form :model="form" status-icon :rules="rules" ref="form" label-width="60px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="编号" prop="clientCode">
              <el-input v-model="form.clientCode" placeholder="客户编号" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="名称" prop="clientName">
              <el-input v-model="form.clientName" placeholder="客户名称" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态">
              <el-switch
                v-model="form.isStop"
                active-color="red"
                inactive-color="green"
                active-text="停用"
                inactive-text="正常"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="联系人">
              <el-input v-model="form.linkMan" placeholder="联系人" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电话">
              <el-input v-model="form.linkTel" placeholder="联系电话" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="客户类别" label-width="70px">
              <type-cascader
                v-model="form.typeId"
                info="ClientType"
                :canAdd="true"
                auth="Client"
                @addClick="()=>{this.clientTypeItem = {}}"
              ></type-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="地址">
          <el-input v-model="form.addres" placeholder="地址" clearable></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark" placeholder="备注" type="textarea" :rows="2" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button type="info" @click="save(false)" :loading="loading">保存关闭</el-button>
            <el-button type="success" @click="save(true)" :loading="loading" v-if="!item.id">保存新增</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div>
      <add-type typeKind="ClientType" :item="clientTypeItem" @success="id=>{this.form.typeId=id}"></add-type>
    </div>
  </div>
</template>

<script>
import TypeCascader from "@/components/TypeCascader";
import AddType from "@/components/BaseType/TypeItem";
export default {
  components: {
    TypeCascader,
    AddType
  },
  props: ["item"],
  data() {
    return {
      visible: false,
      loading: false,
      form: {},
      rules: {
        clientCode: [this.$g.required],
        clientName: [this.$g.required]
      },
      clientTypeItem: {}
    };
  },
  watch: {
    item: {
      handler: function(value) {
        if (value.id) {
          this.loading = true;
          this.$get("Client/Detail", {
            id: value.id
          })
            .then(r => {
              this.form = r;
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.reset(value.typeId);
        }
        this.visible = true;
      },
      deep: true
    }
  },
  methods: {
    reset(typeId) {
      this.form = {
        typeId: typeId || null,
        isStop: false
      };
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
    },
    save(isContinue) {
      this.$refs["form"].validate(valid => {
        if (!valid) {
          return;
        }
        this.loading = true;
        let values = this.form;
        if (values.id) {
          this.$post("Client/EditClient", values)
            .then(r => {
              this.$message.success("操作成功！");
              this.visible = false;
              this.$store.commit("updateBaseInfo", {
                info: "Client",
                item: r
              });
              this.$emit("success");
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$post("Client/AddClient", values)
            .then(r => {
              this.$message.success("操作成功！");
              this.$store.commit("addBaseInfo", {
                info: "Client",
                item: r
              });
              if (isContinue) {
                this.reset();
              } else {
                this.visible = false;
              }
              this.$emit("success", r.id);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style>
</style>