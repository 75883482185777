const arrayToTree = function (array, parentId) {
    let temp = [];

    array.forEach(item => {
        if (item.parentId == parentId) {
            let node = {
                ...item
            }
            // 递归调用此函数
            let children = arrayToTree(array, node.id);
            if (children.length > 0) {
                node.children = children;
            }
            temp.push(node);
        }
    });
    return temp.sort((a, b) => {
        var x = a.code || "";
        var y = b.code || "";
        return x.localeCompare(y);
    });
}
//本身Id以及子Id 本身id 在数组第一个
const getCildrenIdArray = function (item) {
    let temp = [];
    temp.push(item.id);
    if (item.children) {
        item.children.forEach(r => {
            temp.push(...getCildrenIdArray(r));
        })
    }
    return temp;
}

//获取所有父id  本身id 在返回数组最后
const getParentIdArray = function (array, id) {
    let temp = [];
    array.forEach(item => {
        if (item.id == id) {
            if (item.parentId != null) {
                temp.push(...getParentIdArray(array, item.parentId));
            }
            temp.push(item.id)
        }
    });
    return temp;
}
export default {
    arrayToTree,
    getCildrenIdArray,
    getParentIdArray
};