<template>
  <div>
    <el-dialog
      :title="isEdit?'变更合同':'新增合同'"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form :model="form" status-icon :rules="rules" ref="form" label-width="80px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="合同编号" prop="billCode">
              <el-input v-model="form.billCode" placeholder="合同编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="签订日期" prop="billDate">
              <el-date-picker
                style="width:150px"
                @change="getBillCode(2)"
                v-model="form.billDate"
                placeholder="签订日期"
                :clearable="false"
                :editable="false"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合同名称" prop="contractName">
              <el-input v-model="form.contractName" placeholder="合同名称" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="所属项目" prop="projectId">
              <el-input v-if="isEdit" v-model="form.projectName" :disabled="true"></el-input>
              <project-select
                v-else
                v-model="form.projectId"
                :canAdd="true"
                :state="[1,2]"
                @addClick="()=>{this.projectItem = {}}"
              ></project-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="对方公司" prop="clientId">
              <el-input v-if="isEdit" v-model="form.clientName" :disabled="true"></el-input>
              <base-select
                v-else
                info="Client"
                v-model="form.clientId"
                :canAdd="true"
                @addClick="()=>{this.clientItem = {}}"
              ></base-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合同金额" prop="contractAmount">
              <el-popover ref="contractAmount" placement="top-start" trigger="focus">
                <span style="color: teal;">{{form.contractAmount|money}}</span>
              </el-popover>
              <el-input
                v-popover:contractAmount
                type="number"
                v-model="form.contractAmount"
                placeholder="合同金额"
                @mousewheel.native.prevent
              >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="开始日期">
              <el-date-picker style="width:150px" v-model="form.startDate" placeholder="开始日期"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结束日期">
              <el-date-picker style="width:150px" v-model="form.endDate" placeholder="结束日期"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="isEdit">
            <el-form-item label="结算金额">
              <el-input :value="thousandsFormat(form.balanceAmount)" :disabled="true">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="简要条款">
          <el-input v-model="form.remark" placeholder="简要条款" type="textarea" :rows="2" clearable></el-input>
        </el-form-item>
        <el-form-item label="附件">
          <BillAttach :attachId="form.attachId" ref="attach"></BillAttach>
        </el-form-item>
        <el-form-item label="变更说明" v-if="isEdit" prop="changeRemark">
          <el-input
            v-model="form.changeRemark"
            placeholder="变更说明"
            type="textarea"
            :rows="2"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button type="info" @click="save(false)" :loading="loading">保存关闭</el-button>
            <el-button type="success" @click="save(true)" :loading="loading" v-if="!isEdit">保存新增</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div>
      <add-project :item="projectItem" @success="(id)=>{this.form.projectId = id;}"></add-project>
      <add-client :item="clientItem" @success="(id)=>{this.form.clientId = id;}"></add-client>
    </div>
  </div>
</template>

<script>
import billcode from "@/common/billcodeMixins.js";
import ProjectSelect from "@/components/ProjectSelect.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import AddProject from "@/views/project/Item.vue";
import AddClient from "@/views/client/Item.vue";
import setName from "@/common/setName";
import guid from "@/common/guid.js";
import BillAttach from "@/components/BillAttach";
export default {
  props: ["item"],
  mixins: [billcode],
  components: {
    AddProject,
    AddClient,
    ProjectSelect,
    BaseSelect,
    BillAttach,
  },
  data() {
    return {
      visible: false,
      loading: false,
      form: {},
      rules: {
        billCode: [this.$g.required],
        billDate: [this.$g.required],
        contractName: [this.$g.required],
        projectId: [this.$g.required],
        clientId: [this.$g.required],
        contractAmount: [this.$g.required, this.$g.moreThanZero],
        changeRemark: [this.$g.required],
      },
      projectItem: {},
      clientItem: {},
    };
  },
  computed: {
    isEdit() {
      return !(this.item.id == undefined);
    },
  },
  watch: {
    item: {
      handler: function (value) {
        if (value.id) {
          this.loading = true;
          this.$get("Contract/Detail", {
            id: value.id,
          })
            .then((r) => {
              setName("Project", r, "projectId", "projectName");
              setName("Client", r, "clientId", "clientName");
              this.form = r;
              if (this.$refs["form"]) {
                this.$refs["form"].resetFields();
              }
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.reset();
        }
        this.visible = true;
      },
      deep: true,
    },
  },
  methods: {
    reset() {
      this.form = {
        billCode: "",
        billDate: new Date(),
        projectId: "",
        clientId: "",
        attachId: guid(),
      };
      this.getBillCode(2);
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
    },
    save(isContinue) {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.loading = true;
        let values = this.form;
        if (values.id) {
          this.$post("Contract/EditContract", values)
            .then((r) => {
              this.$message.success("操作成功！");
              this.visible = false;
              this.$emit("success");
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$post("Contract/AddContract", values)
            .then((r) => {
              this.$message.success("操作成功！");
              if (isContinue) {
                this.reset();
              } else {
                this.visible = false;
              }
              this.$emit("success", r.id);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style>
</style>