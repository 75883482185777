<template>
  <el-select
    :value="value"
    @change="
      (v) => {
        this.$emit('input', v);
      }
    "
    placeholder="请选择"
    clearable
    filterable
    :filter-method="filter"
    @clear="filter()"
    ref="select"
    @visible-change="bottomAction"
  >
    <el-option
      v-for="item in list"
      :key="item.id"
      :value="item.id"
      :label="item.name"
    >
      <span class="code">{{ item.code }}</span>
      <span style="float: right">{{ item.name }}</span>
    </el-option>
  </el-select>
</template>

<script>
// import ProjectSelect from "@/components/ProjectSelect.vue";
//  <project-select  v-model="supplierId" :canAdd="true" :state="[1,2]" @addClick="addClick"></project-select>
import selectBottomAction from "@/common/selectBottomMixins.js";

export default {
  name: "ProjectSelect",
  props: ["value", "canAdd", "state", "addId"],
  mixins: [selectBottomAction],
  data() {
    return {
      list: [],
    };
  },
  computed: {
    listCopy() {
      let res;
      if (this.state || this.addId) {
        res = this.$store.state.baseinfo["Project"].filter((item) => {
          if (
            (this.state && this.state.indexOf(item.billState) >= 0) ||
            (this.addId && item.id == this.addId)
          ) {
            return true;
          }
        });
      } else {
        res = this.$store.state.baseinfo["Project"];
      }
      return res;
    },
  },
  watch: {
    listCopy: function () {
      this.filter();
    },
  },
  mounted() {
    this.filter();
  },
  activated() {
    this.filter();
  },
  methods: {
    filter(val) {
      if (val) {
        this.list = this.listCopy.filter((item) => {
          if (
            item.name.indexOf(val) >= 0 ||
            item.pinYin.indexOf(val.toLowerCase()) >= 0 ||
            item.code.indexOf(val) >= 0
          ) {
            return true;
          }
        });
      } else {
        this.list = this.listCopy;
      }
    },
    bottomAction(v) {
      if (this.canAdd) {
        this.selectBottomAction(v, {
          auth: "Project",
          click: () => {
            this.$emit("addClick");
          },
        });
      }
      this.filter();
    },
  },
};
</script>

<style scoped>
.code {
  float: left;
  color: #999;
  font-size: 12px;
  margin-right: 20px;
}
</style>