<template>
  <el-cascader
    :value="selectedArray"
    @change="handleChange"
    clearable
    :options="options"
    :props="{checkStrictly: true, expandTrigger: 'hover',value:'id',label:'name' }"
    ref="select"
    @visible-change="bottomAction"
  ></el-cascader>
</template>

<script>
// import TypeCascader from "@/components/TypeCascader";
//   <type-cascader v-model="form.employeeTypeId" info="EmployeeType"  :canAdd="true" auth="Employee" @addClick="()=>{this.employeeTypeItem = {}}"></type-cascader>
import tree from "@/common/tree.js";
import selectBottomAction from "@/common/selectBottomMixins.js";
export default {
  name: "TypeCascader",
  props: ["info", "value", "canAdd", "auth"],
  mixins: [selectBottomAction],
  data() {
    return {
      selectedArray: []
    };
  },
  activated() {
    let v = this.value;
    if (!v) {
      this.selectedArray = [];
    } else {
      let array = this.$store.state.baseinfo[this.info];
      this.selectedArray = tree.getParentIdArray(array, v);
    }
  },
  watch: {
    value(v) {
      if (!v) {
        this.selectedArray = [];
      } else {
        let array = this.$store.state.baseinfo[this.info];
        this.selectedArray = tree.getParentIdArray(array, v);
      }
    }
  },
  computed: {
    options() {
      let array = this.$store.state.baseinfo[this.info];
      return tree.arrayToTree(array, null);
    }
  },
  methods: {
    handleChange(v) {
      if (!v || v.length == 0) {
        this.$emit("input", null);
      } else {
        this.$emit("input", v[v.length - 1]);
      }
    },
    bottomAction(v) {
      if (this.canAdd) {
        this.selectBottomAction(v, {
          auth: this.auth,
          click: () => {
            this.$emit("addClick");
          }
        });
      }
    }
  }
};
</script>

<style>
</style>